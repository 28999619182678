import React from "react"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import { HomeLink, NewPatientsLink } from "../components/links"
import Alert from "../components/alert"

const YourFirstVisitPage = () => (
  <>
    <SEO title="New patients" />

    <Breadcrumbs>
      <HomeLink />
      <NewPatientsLink />
    </Breadcrumbs>

    <article className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
      <h2>New patients</h2>
      <Alert>
        <span className="text-brand-primary dark:text-brand-secondary font-bold">
          <i>
            Your first visit will consist of a complimentary consultation
            explaining your diagnosis and treatment options.
          </i>
        </span>
      </Alert>
      <div>
        <p className="mb-1">
          Please assist us by providing the following information at the time of
          your consultation, if applicable:
        </p>
        <ul>
          <li>A list of medications you are presently taking</li>
          <li>A referral slip from your dentist</li>
          <li>Dental x-rays</li>
          <li>Dental insurance plan name and policy number</li>
        </ul>

        <p>
          If your dentist has taken x-rays, you may request that they forward
          them to our office. If additional x-rays are necessary, they can be
          taken at our clinic.
        </p>
        <p>
          If possible, patients below 19 years of age should be accompanied by a
          parent or guardian at the first consultation visit. This is to ensure
          a clear understanding of the consultation and treatment planned for
          the patient.
        </p>
        <h3>Financial Information</h3>
        <p>
          We offer an interest-free financial payment plan so that all
          orthodontic treatment is affordable. If you have insurance benefits,
          we will help you with a pre-treatment proposal and claim submissions
          so that your insurance benefits can be applied toward orthodontic
          treatment.
        </p>
        <p>
          For your convenience, we accept{" "}
          <b>Mastercard, Visa, debit card, cheque, and cash.</b>
        </p>
      </div>
    </article>
  </>
)

export default YourFirstVisitPage
